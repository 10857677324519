import { z } from 'zod'

export const zUpdateAppConfigTrpcInput = z.object({
  openaiApiKeys: z.string().min(1).array(),
  freePlanMonthlyCoinsLimits: z.number().int().positive().array(),
  paidPlanMonthlyDollarsLimit: z.number().int().positive(),
  paidPlanMonthlyDollarsPrelimit: z.number().int().positive(),
  aiEndpointsIsDown: z.boolean(),
  minYearSubscriptionPrice: z.coerce.number().positive(),
  minMonthSubscriptionPrice: z.coerce.number().positive(),
  minWeekSubscriptionPrice: z.coerce.number().positive(),
  abTestPolicy: z.enum(['nonorganic', 'everyone']),
})
