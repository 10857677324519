import { zRequestResetPasswordTrpcInput } from '@calories/admin-backend/src/router/auth/requestResetPassword/input'
import { Alert } from '../../../components/Alert'
import { Button, Buttons, LinkButton } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { Segment } from '../../../components/Segment'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getSignInRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

export const RequestResetPasswordPage = withPageWrapper({
  redirectAuthorized: true,
  title: 'Restore Password',
})(() => {
  const requestResetPassword = trpc.requestResetPassword.useMutation()
  const { formik, buttonProps, alertProps } = useForm({
    initialValues: {
      email: '',
    },
    validationSchema: zRequestResetPasswordTrpcInput,
    onSubmit: async (values) => {
      await requestResetPassword.mutateAsync(values)
    },
    resetOnSuccess: true,
    successMessage: 'Check your e-mail for further instructions',
  })

  return (
    <Segment title="Restore Password">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="E-mail" name="email" formik={formik} />
          <Alert {...alertProps} />
          <Buttons>
            <Button {...buttonProps}>Restore Password</Button>
            <LinkButton color="neutral" to={getSignInRoute()}>
              Sign In
            </LinkButton>
          </Buttons>
        </FormItems>
      </form>
    </Segment>
  )
})
